import React from 'react'

const TopColleges = () => {
  return (
    <div>
      <h1>Top Colleges page is on maintenance</h1>
    </div>
  )
}

export default TopColleges

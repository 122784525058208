import React from 'react';



const OurTeam = () => {
  return (
    <div className="py-5 team4">
    <div className="container">
        <div className="row justify-content-center mb-4">
            <div className="col-md-7 text-center">
                <h3 className="mb-3">Experienced & Professional Team</h3>
                <h6 className="subtitle">You can rely on our amazing features list and also our customer services will be a great experience for you without doubt and in no-time</h6>
            </div>
        </div>
        <div className="row">
            {/* Team member 1 */}
            <div className="col-lg-3 mb-4">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t1.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                        <div className="pt-2">
                            <h5 className="mt-4 font-weight-medium mb-0">Michael Doe</h5>
                            <h6 className="subtitle mb-3">Property Specialist</h6>
                            <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Team member 2 */}
            <div className="col-lg-3 mb-4">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t2.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                        <div className="pt-2">
                            <h5 className="mt-4 font-weight-medium mb-0">John Doe</h5>
                            <h6 className="subtitle mb-3">Marketing Specialist</h6>
                            <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Team member 3 */}
            <div className="col-lg-3 mb-4">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t3.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                        <div className="pt-2">
                            <h5 className="mt-4 font-weight-medium mb-0">Jane Doe</h5>
                            <h6 className="subtitle mb-3">Finance Manager</h6>
                            <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Team member 4 */}
            <div className="col-lg-3 mb-4">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                        <div className="pt-2">
                            <h5 className="mt-4 font-weight-medium mb-0">Emily Doe</h5>
                            <h6 className="subtitle mb-3">Graphic Designer</h6>
                            <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                                <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <div className="container">
    <div className="row justify-content-center mb-4">
    </div>
    <div className="row">
        {/* Team member 1 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t1.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Michael Doe</h5>
                        <h6 className="subtitle mb-3">Property Specialist</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 2 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t2.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">John Doe</h5>
                        <h6 className="subtitle mb-3">Marketing Specialist</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 3 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t3.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Jane Doe</h5>
                        <h6 className="subtitle mb-3">Finance Manager</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 4 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Emily Doe</h5>
                        <h6 className="subtitle mb-3">Graphic Designer</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <br/>
    <div className="container">
    <div className="row justify-content-center mb-4">
    </div>
    <div className="row">
        {/* Team member 1 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t1.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Michael Doe</h5>
                        <h6 className="subtitle mb-3">Property Specialist</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 2 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t2.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">John Doe</h5>
                        <h6 className="subtitle mb-3">Marketing Specialist</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 3 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t3.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Jane Doe</h5>
                        <h6 className="subtitle mb-3">Finance Manager</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 4 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Emily Doe</h5>
                        <h6 className="subtitle mb-3">Graphic Designer</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <br/>
    <div className="container">
    <div className="row justify-content-center mb-4">
    </div>
    <div className="row">
        {/* Team member 1 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t1.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Michael Doe</h5>
                        <h6 className="subtitle mb-3">Property Specialist</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 2 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t2.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">John Doe</h5>
                        <h6 className="subtitle mb-3">Marketing Specialist</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 3 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t3.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Jane Doe</h5>
                        <h6 className="subtitle mb-3">Finance Manager</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* Team member 4 */}
        <div className="col-lg-3 mb-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
                    <div className="pt-2">
                        <h5 className="mt-4 font-weight-medium mb-0">Emily Doe</h5>
                        <h6 className="subtitle mb-3">Graphic Designer</h6>
                        <p>You can rely on our amazing features list and also our customer services will be a great experience.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="#" className="text-decoration-none d-block px-1"><i className="icon-social-behance"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
  )
}

export default OurTeam
